import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { getMethod, postMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { useNavigate } from "react-router-dom";

const AddExpense = () => {
  const [ExpenseTypes, setExpenseTypes] = useState([]);
  const [Users, setUsers] = useState([]);
  const [currencies, setCurrencies] = useState([])
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    debit_from: 0,
    expense_type_id: 0,
    payment_type: "cash",
    currency_id: 0,
    amount: "",
    date: "",
    note: "",
  });

  const [errors, setErrors] = useState({
    debit_from: "",
    expense_type_id: "",
    payment_type: "",
    currency_id: "",
    amount: "",
    note: "",
  });

  const getExpenseTypes = async () => {
    await getMethod(`${Apiurl.expense_type}?checkForPermission=false`, {
      page: 1,
      pageSize: 5000
    })
      .then((res) => {
        setExpenseTypes(res.data.data);
      })
      .catch((error) => {
      });
  };

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = { ...errors };

    if (!formValue.debit_from) {
      newErrors.debit_from = "Debit from is required.";
      formIsValid = false;
    } else {
      newErrors.debit_from = "";
    }

    if (!formValue.expense_type_id) {
      newErrors.expense_type_id = "Expense Type is required.";
      formIsValid = false;
    } else {
      newErrors.expense_type_id = "";
    }

    if (!formValue.currency_id) {
      newErrors.currency_id = "Currency Type is required.";
      formIsValid = false;
    } else {
      newErrors.currency_id = "";
    }

    if (!formValue.amount) {
      newErrors.amount = "Amount is required.";
      formIsValid = false;
    } else {
      newErrors.amount = "";
    }

    if (!formValue.date) {
      newErrors.date = "Date is required.";
      formIsValid = false;
    } else {
      newErrors.date = "";
    }

    if (!formValue.note) {
      newErrors.note = "Note is required.";
      formIsValid = false;
    } else {
      newErrors.note = "";
    }

    if (!formIsValid) {
      setErrors(newErrors);
      return;
    }

    await postMethod(Apiurl.expense, formValue)
      .then((res) => {
        setFormValue({
          debit_from: 0,
          expense_type_id: 0,
          payment_type: "cash",
          currency_id: 0,
          amount: "",
          date: "",
          note: "",
        });
        setErrors({});
        // navigate("/allexpense");
      })
      .catch((error) => {
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  useEffect(() => {
    getExpenseTypes();
    getUsers();
    getCurrencies()
  }, []);
  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New Expense</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Debit From
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="debit_from"
                          value={formValue.debit_from}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select user...</option>
                          {Users &&
                            Users.map((user, i) => (
                              <option key={i} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                        </select>
                        {errors.debit_from && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.debit_from}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Expense Type
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="expense_type_id"
                          value={formValue.expense_type_id}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select types...</option>
                          {ExpenseTypes &&
                            ExpenseTypes.map((item, i) => (
                              <option key={i} value={item.id}>
                                {item.type}
                              </option>
                            ))}
                        </select>
                        {errors.expense_type_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.expense_type_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Select Currency Type
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="currency_id"
                          value={formValue.currency_id}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select types...</option>
                          {currencies.map((item, i) => (
                            <option key={i} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.currency_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.amount}
                          onChange={handleChange}
                          className="form-control"
                          name="amount"
                          id="inputEmail3"
                          placeholder="Enter Amount"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.amount}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          value={formValue.date}
                          name="date"
                          onChange={handleChange}
                          className="form-control"
                          id="inputEmail3"
                          placeholder="Enter name"
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.note}
                          onChange={handleChange}
                          className="form-control"
                          name="note"
                          id="inputEmail3"
                          placeholder="Enter note"
                        />
                        {errors.note && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "14px",
                            }}
                          >
                            {errors.note}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddExpense;
