import React, { useEffect, useState } from 'react';
import { getMethod } from '../../services/base_service';
import { debounce } from 'lodash';
import Navbar from './Navbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../services/Pagination';
import { Apiurl } from '../../services/apiRoute';


const UserCollectionList = () => {

    const location = useLocation()
    const user_id = location?.state?.user_id

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(50);

    // const [searchQuery, setSearchQuery] = useState("");
    // const [inputTimeout, setInputTimeout] = useState(null);
    // const [searchComp, setSearchComp] = useState("");

    // const [order, setOrder] = useState("")

    // const permissions = JSON.parse(localStorage.getItem("permissions"));
    // const rolePermissions = permissions.find(
    //     (item) => item.table_name === "deposit_withdrawals"
    // );
    // const isAdmin = localStorage.getItem("isAdmin") === "true";

    const navigate = useNavigate()

    const handleItemsPerPageChange = (event) => {
        setLimit(Number(event.target.value));
        delayedSearch();
    };

    const getUserCollectionList = async () => {
        try {
            setIsLoading(true);
            const response = await getMethod(`${Apiurl.collection}/collections?user_id=${user_id}`);
            setTableData(response.data?.data);
            setTotalPages(Math.ceil(response.data?.data.length / limit));
            setIsLoading(false);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const slicedCollections = tableData.slice((currentPage - 1) * limit, currentPage * limit);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // const handleOrder = () => {
    //     setOrder(order === "DESC" ? "ASC" : "DESC");
    // }


    const delayedSearch = debounce(() => {
        getUserCollectionList();
    }, 300);

    useEffect(() => {
        delayedSearch();
    }, []);

    useEffect(() => {
        if (user_id === undefined || user_id === "") {
            navigate('/user-collections')
        }
    }, []);

    // const handleSearch = (search) => {
    //     setSearchComp(search);
    //     if (inputTimeout) clearTimeout(inputTimeout);
    //     setInputTimeout(
    //         setTimeout(() => {
    //             setSearchQuery(search);
    //             setCurrentPage(1);

    //         }, 500)
    //     );
    // };


    const handleExcelSheet = async () => {
        try {
            setIsLoading(true);
            // if (dateRange.start_date && dateRange.end_date && dateRange.end_date >= dateRange.start_date) {
            const response = await getMethod(`${Apiurl.user_useCollection_excel}?user_id=${user_id}&search=`);
            const path = (Apiurl.routePath.replace('/api/v1', '') + response.data.file_name)
            generateExcel(path, "file.xlsx")
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const generateExcel = async (path, name) => {
        window.open(path)
    }

    return (
        <div>
            <Navbar />
            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1>User Collection List</h1>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                                    <div className="mb-3 mb-xl-0 mr-5">
                                        <span className="btn btn-dark" onClick={handleExcelSheet}>Export To Excel<i className="fa fa-download ml-2 text-success" aria-hidden="true"></i></span>
                                    </div>
                                    <div className="mb-3 mb-xl-0">
                                        <Link to="/user-collections">
                                            <span className="btn btn-dark">Go To UserCollection</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            {/* <div className="card">
                                <div className="body">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            onChange={(e) => { handleSearch(e.target.value); }}
                                            value={searchComp}
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="search-mail">
                                                <i className="fa fa-search text-info"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div> */}

                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                <>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    {/* <th style={{ cursor: "pointer" }} onClick={() => setOrder("")}>Date</th> */}
                                                    <th>Date</th>
                                                    <th>MT5 Account No.</th>
                                                    <th>Account Name</th>
                                                    <th>User Name</th>
                                                    <th>Transaction By</th>
                                                    <th>Transaction Type</th>
                                                    <th>Currency Name</th>
                                                    <th>Debit Amount</th>
                                                    <th>Credit Amount</th>
                                                    {/* <th>Currency Buy/sell</th>
                                                    <th>Deposit/withdraw</th>
                                                    <th>Income/Expense</th>
                                                    <th>Transfer From</th>
                                                    <th>Credit In/Out</th> */}

                                                    {/* <th style={{ cursor: "pointer" }} onClick={handleOrder}>Account No {" "}
                                                        <i className="fa fa-sort-numeric-asc text-success" aria-hidden="true"></i></th> */}
                                                    {/* {(isAdmin ||
                                                        rolePermissions?.edit ||
                                                        rolePermissions?.delete) && <th>Action</th>} */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {slicedCollections && slicedCollections.length > 0 ? (
                                                    slicedCollections.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>#{(currentPage - 1) * limit + 1 + i} </td>
                                                                <td>{list?.date ? list?.date : "-"}</td>
                                                                <td>{list?.account_no ? list?.account_no : "-"}</td>
                                                                <td>{list?.terminal ? list?.terminal?.name : "-"}</td>
                                                                <td>{list?.from_users ? list?.from_users?.name : "-"}</td>
                                                                <td>{list?.transaction_by_users ? list?.transaction_by_users?.name : "-"}</td>

                                                                <td>{(list?.table_key === "deposit_withdrawals" && (list.type === "Cr" ? "Withdrawal" : "Deposit")) ||
                                                                    (list?.table_key === "credit_in_out" && (list.type === "Cr" ? "Credit In" : "Credit Out")) ||
                                                                    (list?.table_key === "buy_sell_currency" && (list.type === "Cr" ? "Sell" : "Buy")) ||
                                                                    (list?.table_key === "transfer" && (list?.table_key === "transfer" ? "Debit (Transfer)" : "-")) ||
                                                                    (list?.table_key === "transfer_to" && (list?.table_key === "transfer_to" ? "Credit (Transfer)" : "-")) ||
                                                                    (list?.table_key === "income" && (list?.table_key === "income" ? "Income" : "-")) ||
                                                                    (list?.table_key === "expense" && (list?.table_key === "expense" ? "Expense" : "-"))}
                                                                </td>

                                                                <td>{list?.currency?.name ? list?.currency?.name : "USDT"}</td>

                                                                <td>{((list?.table_key === "deposit_withdrawals" && list.type === "Cr") ||
                                                                    (list?.table_key === "credit_in_out" && list.type === "Dr") ||
                                                                    (list?.table_key === "buy_sell_currency" && list.type === "Cr") ||
                                                                    (list?.table_key === "transfer") ||
                                                                    (list?.table_key === "expense")) ? ((list?.amount ? atob(list?.amount) : (list?.mt5_usdt))) : '-'}
                                                                </td>
                                                                <td>{((list?.table_key === "deposit_withdrawals" && list.type === "Dr") ||
                                                                    (list?.table_key === "credit_in_out" && list.type === "Cr") ||
                                                                    (list?.table_key === "buy_sell_currency" && list.type === "Dr") ||
                                                                    (list?.table_key === "transfer_to") ||
                                                                    (list?.table_key === "income")) ? ((list?.amount ? atob(list?.amount) : (list?.mt5_usdt))) : '-'}
                                                                </td>

                                                                {/* <td>
                                                                {(isAdmin || rolePermissions?.edit) && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-default btn-sm"
                                                                        title="Edit"
                                                                        onClick={() => setEditModal(list.id)}
                                                                    >
                                                                        <i className="fa fa-pencil text-success edit-button"></i>
                                                                    </button>
                                                                )}
                                                                {(isAdmin || rolePermissions?.delete) && (
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-default btn-sm"
                                                                        title="Delete"
                                                                        onClick={() => handleDelete(list.id)}
                                                                    >
                                                                        <i className="fa fa-trash-o text-danger delete-button"></i>
                                                                    </button>
                                                                )}
                                                            </td> */}
                                                            </tr>
                                                        </React.Fragment>
                                                    ))) : (
                                                    <tr>
                                                        <td style={{ borderTop: "none", textAlign: "center" }} colSpan={8}>No records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                    {slicedCollections.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                            limit={limit}
                                            onLimitChange={handleItemsPerPageChange}
                                        />
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserCollectionList;
