import React, { useEffect, useState } from "react";
import Pagination from "../../../services/Pagination";
import Navbar from "../Navbar";
import { getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { debounce } from "lodash";
import { Link } from "react-router-dom";

const AgencyList = () => {
  const [agencies, setAgencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [limit, setLimit] = useState(50);

  const [inputTimeout, setInputTimeout] = useState(null);
  const [searchComp, setSearchComp] = useState("");
  const getAgencies = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.agency, {
      page: currentPage,
      pageSize: 8,
      search: searchQuery,
    })
      .then((res) => {
        setTotalPages(res.data.total_page);
        setAgencies(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  const delayedSearch = debounce(() => {
    getAgencies();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery, limit]);

  const handleSearch = (search) => {
    setSearchComp(search);
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setSearchQuery(search);
        setCurrentPage(1);
      }, 500)
    );
  };

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Agency List</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/add-agency">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="search"

                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      value={searchComp}
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No.</th>
                          <th>Name</th>
                          <th>Address</th>
                          <th>GSTIN</th>
                          <th>Mobile No</th>
                        </tr>
                      </thead>

                      {agencies && agencies.length > 0 ? (
                        agencies.map((list, i) => (
                          <React.Fragment key={i}>
                            <tbody>
                              <tr>
                                <td>#{i + 1}</td>
                                <td>{list.name}</td>
                                <td>{list.address}</td>
                                <td>{list.gst_in}</td>
                                <td>{list.mobile_no}</td>
                              </tr>
                            </tbody>

                          </React.Fragment>
                        ))) : (
                        <tbody>
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={5}>No records found</td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                  {agencies && agencies.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgencyList;
