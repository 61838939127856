import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import debounce from "lodash/debounce";
import { deleteMethod, getMethod, postMethod, putMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { Modal, ModalHeader } from "reactstrap";
import { useForm } from "react-hook-form";
import { showAlert, showConfirmationDialog } from "../../../services/alert_service";

const BuySellList = () => {

  const [currencies, setCurrencies] = useState([]);
  const [totalSum, setTotalSum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [inputTimeout, setInputTimeout] = useState(null);
  const [searchComp, setSearchComp] = useState("");
  const [limit, setLimit] = useState(50);
  const [selectCurrency, setSelectCurrency] = useState("");
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  });
  const [dateError, setDateError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    watch,
  } = useForm();

  const [currenciesList, setCurrenciesList] = useState([])
  // const [users, setUsers] = useState([])
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const rolePermissions = permissions.find(
    (item) => item.table_name === "buy_sell_currencies"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrenciesList(data.data.currency)
    }).catch((error) => {
    })
  }

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const defaultDate = getCurrentDate();

  // const getUsers = async () => {
  //   await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
  //     .then((res) => {
  //       setUsers(res.data.data);
  //     })
  //     .catch((error) => {
  //     });
  // };

  useEffect(() => {
    getCurrencies();
    // getUsers();
  }, []);

  const getBuySellList = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`/buy_sell/list?page=${currentPage}&limit=${limit}&search=${searchQuery}&currency=${selectCurrency}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`);
      setTotalSum(response.data.total_sum);
      setCurrencies(response.data.data);
      setTotalPages(response.data.total_page)
      setIsLoading(false);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  const fetchUpdateData = async (id) => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.currency_buy_sell}/${id}`);
      const info = response.data.data
      if (info === undefined) {
        showAlert('User Data not Found', "error")
        return
      }
      setSelectedUser(response.data.data)

      setError("from_currency_id", { type: "custom", message: "" })
      setError("from_amount", { type: "custom", message: "" })
      setError("to_currency_id", { type: "custom", message: "" })
      setError("to_amount", { type: "custom", message: "" })
      // setError("mt5_usdt", { type: "custom", message: "" })
      setError("rate", { type: "custom", message: "" })
      setError("date", { type: "custom", message: "" })
      setError("note", { type: "custom", message: "" })

      setValue('from_currency_id', info[0]?.type === "Cr" ? info[0]?.currency_id : info[1]?.currency_id);
      setValue('from_amount', info[0]?.type === "Cr" ? atob(info[0]?.amount) : atob(info[1]?.amount));
      setValue('to_currency_id', info[0]?.type === "Dr" ? info[0]?.currency_id : info[1]?.currency_id);
      setValue('to_amount', info[0]?.type === "Dt" ? atob(info[0]?.amount) : atob(info[1]?.amount));
      setValue('rate', info[0]?.rate);
      setValue('date', info[0]?.date);
      setValue('note', info[0]?.note);
      setModal(true);

    } catch (error) {
      console.log('error: ', error)
    } finally {
      setIsLoading(false);
    }
  }

  const handleExcelSheet = async () => {
    try {
      setIsLoading(true);
      // if (dateRange.start_date && dateRange.end_date && dateRange.end_date >= dateRange.start_date) {
      const response = await postMethod(`${Apiurl.buy_sell_currency_excel}?search=${searchQuery}&currency=${selectCurrency}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`);
      const path = (Apiurl.routePath.replace('/api/v1', '') + response.data.file_name)
      generateExcel(path, "file.xlsx")
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const generateExcel = async (path, name) => {
    window.open(path)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  const delayedSearch = debounce(() => {
    getBuySellList();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery, limit, selectCurrency]);

  const handleSearch = (search) => {
    setSearchComp(search);
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setSearchQuery(search);
        setCurrentPage(1);
      }, 500)
    );
  };

  const handleFilterDate = (e) => {
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
    setDateError("");
  }

  const fromAmount = watch("from_amount");
  const toAmount = watch("to_amount");
  const fromCurrencyId = watch("from_currency_id");
  const toCurrencyId = watch("to_currency_id");

  useEffect(() => {
    if (fromAmount && toAmount) {
      const calculatedRate = parseFloat(toAmount) / parseFloat(fromAmount);
      setValue("rate", calculatedRate.toFixed(4));
    } else {
      setValue("rate", "");
    }
  }, [fromAmount, toAmount, setValue]);

  const handleEditSubmit = async (data) => {
    data.user_id = selectedUser[0].user_id
    data.mt5_usdt = 0
    data.from_id = selectedUser[0]?.type === "Cr" ? selectedUser[0]?.id : selectedUser[1]?.id
    data.to_id = selectedUser[0]?.type === "Dr" ? selectedUser[0]?.id : selectedUser[1]?.id

    await putMethod(`${Apiurl.currency_buy_sell}`, data)
      .then((res) => {
        reset()
        setModal(false)
        getBuySellList()
      })
      .catch((error) => {
        // setModal(false)
        console.log('error: ', error)
      });
  }

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.currency_buy_sell}/${id}`)
          .then((res) => {
            getBuySellList();
          })
          .catch((error) => {
          });
      }
    } catch (error) {
    }
  };

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)} className="d-block">
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update currencies Buy/sell</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleEditSubmit)}>

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          From Currency Type
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <select
                          id="from_currency_id"
                          name="from_currency_id"
                          {...register("from_currency_id", {
                            required: "Currency Type is Required.",
                          })}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value=""> Select Currency Type...</option>
                          {currenciesList.map((item) => (
                            <option key={item.id} value={item.id} disabled={item.id == toCurrencyId}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      {errors.from_currency_id && (
                        <p className="useredit_err">{errors.from_currency_id.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          From Amount
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          {...register("from_amount", {
                            required: "Amount is Required.",
                          })}
                          name="from_amount"
                          id="from_amount"
                          // value={watch("amount")}
                          placeholder="Enter Amount"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.from_amount && (
                        <p className="useredit_err">{errors.from_amount.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          To Currency Type
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <select
                          id="to_currency_id"
                          name="to_currency_id"
                          {...register("to_currency_id", {
                            required: "Currency Type is Required.",
                          })}

                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                          <option value=""> Select Currency Type...</option>
                          {currenciesList.map((item) => (
                            <option key={item.id} value={item.id} disabled={item.id == fromCurrencyId}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      {errors.to_currency_id && (
                        <p className="useredit_err">{errors.to_currency_id.message}</p>
                      )}


                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          To Amount
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="number"
                          {...register("to_amount", {
                            required: "Amount is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          name="to_amount"
                          id="to_amount"
                          // value={watch("amount")}
                          placeholder="Enter Amount"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.to_amount && (
                        <p className="useredit_err">{errors.to_amount.message}</p>
                      )}


                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Rate
                          <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>
                        <input
                          type="text"
                          {...register("rate", {
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message: "Please enter a valid rate.",
                            },
                          })}
                          name="rate"
                          id="rate"
                          disabled
                          placeholder="Rate"
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.rate && (
                        <p className="useredit_err">{errors.rate.message}</p>
                      )}


                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Date
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          name="date"
                          id="date"
                          // defaultValue={defaultDate}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>
                      {errors.date && (
                        <p className="useredit_err">{errors.date.message}</p>
                      )}

                      <div className="form-group row justify-content-between align-items-center">
                        <label
                          className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Note
                        </label>
                        <input
                          type="text"
                          {...register("note")}
                          name="note"
                          id="note"
                          // defaultValue={defaultDate}
                          className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                          style={{ marginLeft: "8px" }}
                        >
                        </input>
                      </div>


                      <div className="form-group d-flex justify-content-center">
                        <div className="col-12 text-center mt-2">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Buy Sell Currency List</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0 mr-5">
                    <button className="btn btn-dark" onClick={handleExcelSheet} disabled={currencies?.length === 0}>Export To Excel<i className="fa fa-download ml-2 text-success" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div className="mb-3 mb-xl-0">
                    <Link to="/buy-sell">
                      <span className="btn btn-dark">Buy/Sell</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body d-flex align-items-center flex-sm-nowrap flex-wrap" style={{ gap: "10px" }}>
                  <select onChange={(e) => { setSelectCurrency(e.target.value) }} value={selectCurrency} className="border py-2 px-3 rounded bg-transparent">
                    <option selected value="">Select Currency</option>
                    {currenciesList.map((item) => (
                      <option key={item.id} value={item.name}>{item?.name}</option>
                    ))}
                  </select>
                  <div className="input-group">
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => { handleSearch(e.target.value); }}
                      value={searchComp}
                      className="form-control"
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail" style={{ cursor: "pointer" }}>
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="body">
                    <label style={{ fontWeight: 500 }}>Filter By Date : </label>
                    <form>
                      <div className="input-group flex-sm-nowrap flex-wrap align-items-center" style={{ gap: "10px" }}>
                        <div className="d-flex align-items-center w-100">
                          <p className="m-0">From:</p>
                          <input
                            type="date"
                            name="start_date"
                            style={{ margin: "0px 4px 0px 5px", height: "40px" }}
                            onChange={(e) => {
                              handleFilterDate(e);
                            }}
                            value={dateRange.start_date}
                            max={dateRange?.end_date || defaultDate}
                            className="form-control"
                            placeholder="Start Date..."
                          />
                        </div>
                        <div className="d-flex align-items-center w-100">
                          <p className="m-0">To:</p>
                          <input
                            type="date"
                            name="end_date"
                            style={{ marginLeft: '5px', height: "40px" }}
                            onChange={(e) => {
                              handleFilterDate(e);
                            }}
                            value={dateRange.end_date}
                            min={dateRange?.start_date}
                            max={defaultDate}
                            className="form-control"
                            placeholder="End Date..."
                          />
                          <div className="ml-2" style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="bottom" title="Search">
                            <span className="input-group-text" id="search-mail" onClick={getBuySellList} >
                              <i className="fa fa-search edit-button text-info"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      {dateError && <p style={{ color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "0px" }}>{dateError}</p>}
                    </form>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Date</th>
                          <th>Buy/Sell</th>
                          <th>User Name</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Rate</th>
                          <th>Transaction By</th>
                          <th>Note</th>
                          {(isAdmin ||
                            rolePermissions?.edit ||
                            rolePermissions?.delete) &&
                            <th>Action</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {currencies && currencies.length > 0 ? (
                          currencies.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{(currentPage - 1) * limit + 1 + i}</td>
                                <td>{list.date === "0000-00-00" ? "-" : list.date}</td>
                                <td>{list.type === "Dr" ? "Buy" : "Sell"}</td>
                                <td>{list.users.name}</td>
                                <td>{list.currency.name}</td>
                                <td>{list.amount ? atob(list.amount) : ""}</td>
                                <td>{list.rate}</td>
                                <td>{list?.transaction_by_users?.name || "-"}</td>
                                <td>{list?.note || '-'}</td>
                                <td>
                                  {(isAdmin || rolePermissions?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => fetchUpdateData(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  )}
                                  {(isAdmin || rolePermissions?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger delete-button"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={10}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                      {currencies && currencies.length > 0 ? (
                        <tfoot>
                          <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td style={{ borderTop: '1px solid black', fontWeight: 'bold', color: (totalSum > 0) ? "green" : "red", }}>
                              {parseFloat(totalSum).toFixed(2)}
                            </td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                          </tr>
                        </tfoot>) : <></>}
                    </table>
                  </div>
                  {currencies && currencies.length > 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySellList;
