import React, { useEffect, useState } from 'react';
import Pagination from '../../../services/Pagination';
import { Apiurl } from '../../../services/apiRoute';
import { deleteMethod, getMethod, putMethod } from '../../../services/base_service';
import Navbar from '../Navbar';
import { Modal, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import debounce from "lodash/debounce";
import { showConfirmationDialog } from '../../../services/alert_service';

const AllExpenseType = () => {
  const [expenseType, setExpenseType] = useState("");
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState(0);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  // const permissions = JSON.parse(localStorage.getItem("permissions"));
  // const rolePermissions = permissions.find(
  //   (item) => item.table_name === "expense_types"
  // );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getExpenseTypes = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.expense_type, {
      page: currentPage,
      pageSize: limit,
      search: searchQuery,
    })
      .then((res) => {
        setTotalPages(res.data.total_page);
        setExpenseTypes(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = expenseTypes.find((item) => item.id === id);
    setSelected(selected.id);
    setExpenseType(selected.type);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const params = {
      id: selected,
      type: expenseType,
    };
    if (expenseType === "") {
      setErrors({ expenseType: "Expense Type Cannot be empty" });
    }
    if (expenseType) {
      const response = await putMethod(Apiurl.expense_type, params);
      if (response.status === 200) {
        setModal(!modal);
        getExpenseTypes();
        setExpenseType("");
        setErrors({ ...errors, expenseType: "" });
      }
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.expense_type}/${id}`)
          .then((res) => {
            getExpenseTypes();
          })
          .catch((error) => {
          });
      }
    } catch (error) {
    }
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  const delayedSearch = debounce(() => {
    getExpenseTypes();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery, limit]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Expense Type</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleEditSubmit}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px", marginTop: "-15px" }}
                        >
                          Expense Types
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            value={expenseType}
                            name="type"
                            className="form-control"
                            onChange={(e) => setExpenseType(e.target.value)}
                            style={{ width: "300px" }}
                            placeholder="Enter Expense Type"
                          />
                          {errors.expenseType && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {errors.expenseType}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success"
                            style={{
                              backgroundColor: "#82b440",
                              marginRight: "30px",
                            }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => { setModal(false); setErrors({}) }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Expense Types</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addexpensetype">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        setCurrentPage(1);
                      }}
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Expense Type</th>
                          {/* <th>Actions</th> */}
                          {isAdmin &&
                            <th>Action</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {expenseTypes.length > 0 ? (
                          expenseTypes.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{(currentPage - 1) * limit + 1 + i}</td>
                                <td>{list.type}</td>
                                <td>
                                  {isAdmin && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm edit-buton"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  )}
                                  {isAdmin && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger delete-button"></i>
                                    </button>
                                  )}
                                </td>

                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={3}>No records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {expenseTypes.length > 0 &&
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllExpenseType;
