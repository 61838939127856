import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { postMethod, getMethod } from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { useNavigate } from "react-router-dom";
import "../../css/sidebar.css";


const AddUser = () => {
  const [roles, setRoles] = useState([]);
  const [formValue, setFormValue] = useState({
    role_id: 0,
    name: "",
    mobile_no: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const getRoles = async () => {
    await getMethod(`${Apiurl.role}?checkForPermission=false`, {
    page: 1,
    pageSize: 1000,
    search: "",
    }
    )
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((error) => {
      });
  };

  const validationRules = {
    role_id: "Please select role type",
    name: "Name is required",
    mobile_no: "Mobile No. is required",
    email: "Email is required",
    password: "Password is required",
  };

  const validateForm = () => {
    let formIsValid = true;
    const newErrors = {};
    Object.entries(validationRules).forEach(([key, message]) => {
      if (!formValue[key]) {
        newErrors[key] = message;
        formIsValid = false;
      }
    });
    const mobileNoRegex = /^\d{10}$/;
    if (formValue.mobile_no && !mobileNoRegex.test(formValue.mobile_no)) {
      newErrors.mobile_no = "Mobile No must be 10 digits.";
      formIsValid = false;
    }
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (formValue.email && !emailRegex.test(formValue.email)) {
      newErrors.email = "Enter a valid email address.";
      formIsValid = false;
    }
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (formValue.password && !passwordRegex.test(formValue.password)) {
      newErrors.password =
        "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.";
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (validateForm()) {
        const response = await postMethod(Apiurl.register, formValue);
        setFormValue({
          role_id: 0,
          name: "",
          mobile_no: "",
          email: "",
          password: "",
        });
        setErrors({});
      }
    } catch (error) {
      if (error.error === "Record Already Exists") {
        setErrors({ form: "User with this mobile number already exists." });
      } else
        setErrors({
          form: "An error occurred while adding the user. Please try again.",
        });
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Add New User</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Role Id
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select
                          name="role_id"
                          value={formValue.role_id}
                          onChange={handleChange}
                          className="form-control input-height"
                        >
                          <option value="">Select Role</option>
                          {roles.map((role, i) => (
                            <option key={i} value={role.id}>
                              {role.role}
                            </option>
                          ))}
                        </select>
                        {errors.role_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.role_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.name}
                          onChange={handleChange}
                          className="form-control"
                          name="name"
                          id="inputEmail3"
                          placeholder="Enter Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Mobile No
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.mobile_no}
                          onChange={handleChange}
                          className="form-control"
                          maxLength={10}
                          pattern="[0-9]*"
                          name="mobile_no"
                          id="inputEmail3"
                          placeholder="Enter Mobile No."
                        />
                        {errors.mobile_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.mobile_no}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Email
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.email}
                          onChange={handleChange}
                          className="form-control"
                          id="inputEmail3"
                          name="email"
                          placeholder="Enter Email Address "
                        />
                        {errors.email && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Password
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          value={formValue.password}
                          onChange={handleChange}
                          data-provide="datepicker"
                          data-date-autoclose="true"
                          name="password"
                          className="form-control"
                          placeholder="Enter Password "
                        />
                        {errors.password && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.password}
                          </p>
                        )}
                      </div>
                    </div>
                    {errors.form && (
                      <p style={{ color: "red", fontSize: "13px" }}>
                        {errors.form}
                      </p>
                    )}
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
