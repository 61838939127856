
import React, { useEffect, useState } from 'react';
import { deleteMethod, getMethod, postMethod, putMethod } from '../../../services/base_service';
import { debounce } from 'lodash';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../../../services/Pagination';
import { Apiurl } from '../../../services/apiRoute';
import { showConfirmationDialog } from '../../../services/alert_service';
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader } from 'reactstrap';

const AllTransferList = () => {

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [inputTimeout, setInputTimeout] = useState(null);
    const [searchComp, setSearchComp] = useState("");
    const [dateRange, setDateRange] = useState({
        start_date: "",
        end_date: "",
    });
    const [dateError, setDateError] = useState("");
    const [users, setUsers] = useState([])
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [fromAccNo, setFromAccNo] = useState("")
    const [toAccNo, setToAccNo] = useState("")
    const [limit, setLimit] = useState(50);
    // const [excelPath, setExcelPath] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        setError,
    } = useForm();

    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolePermissions = permissions.find(
        (item) => item.table_name === "mt5usdt_transaction"
    );
    const isAdmin = localStorage.getItem("isAdmin") === "true";



    const getDepositWithdrawalList = async () => {
        try {
            setIsLoading(true);
            const response = await getMethod(`${Apiurl.mt5_usdt_list}?page=${currentPage}&limit=${limit}&search=${searchQuery}`);
            setTableData(response.data.data);
            setTotalPages(response.data.total_page);
            setIsLoading(false);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const getUsers = async () => {
        await getMethod(`${Apiurl.filter_user_list}`)
            .then((res) => {
                // console.log('res>>>>: ', res.data.data)
                // const filtreredUsers = res.data.data.filter((user) => user.role.role !== "MANAGER (STAFF)")
                // console.log('filtreredUsers: ', filtreredUsers)
                setUsers(res.data.data);
            })
            .catch((error) => {
            });
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        setLimit(Number(event.target.value));
    };

    const delayedSearch = debounce(() => {
        getDepositWithdrawalList();
    }, 300);

    useEffect(() => {
        delayedSearch();
    }, [currentPage, searchQuery, limit]);

    useEffect(() => {
        getUsers()
    }, []);

    const handleSearch = (search) => {
        setSearchComp(search);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setSearchQuery(search);
                setCurrentPage(1);
                setDateRange({ start_date: "", end_date: "" })
                setDateError("")
            }, 500)
        );
    };

    const handleFilterDate = (e) => {
        setDateRange({ ...dateRange, [e.target.name]: e.target.value });
        setDateError("");
    }

    const handleFilteredResult = async () => {
        try {
            setIsLoading(true);
            if (dateRange.start_date && dateRange.end_date && dateRange.end_date >= dateRange.start_date) {
                const response = await getMethod(`${Apiurl.mt5_usdt_list}?page=${currentPage}&limit=${limit}&search=${searchQuery}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}`);
                setTableData(response.data.data);
                setTotalPages(response.data.total_page);
            } else {
                setDateError("Please select proper date range")
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }


    const handleDelete = async (id) => {
        try {
            const confirmation = await showConfirmationDialog(
                "Are you sure you want to delete ?",
                "",
                "warning"
            );
            if (confirmation) {
                await deleteMethod(`${Apiurl.mt5_usdt}/${id}`)
                    .then((res) => {
                        getDepositWithdrawalList();
                    })
                    .catch((error) => {
                    });
            }
        } catch (error) {
        }
    };

    const handleFromAccount = (e) => {
        setError("from_account_no", { type: "custom", message: "" })
        // setError("from_name", { type: "custom", message: "" })
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setFromAccNo(e.target.value);
            }, 500)
        );
    }

    const handleToAccount = (e) => {
        setError("to_account_no", { type: "custom", message: "" })
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setToAccNo(e.target.value);
            }, 500)
        );
    }

    const delayedSearchFrom = debounce(() => {
        findFromAccount();
    }, 500);

    const delayedSearchTo = debounce(() => {
        findToAccount();
    }, 500);

    useEffect(() => {
        delayedSearchFrom()
    }, [fromAccNo]);

    useEffect(() => {
        delayedSearchTo()
    }, [toAccNo]);

    const findFromAccount = async () => {
        if (fromAccNo === undefined || fromAccNo === "") {
            return;
        }
        await postMethod(`${Apiurl.findAccounts}/${fromAccNo}`)
            .then((res) => {
                if (res.status === 404) {
                    setValue("from_name", res.data?.account?.name);
                    setError("from_name", { type: "custom", message: "" })
                } else if (res.status === 403) {
                    setValue("from_name", res.data.account?.name);
                    setError("from_name", { type: "custom", message: "" })
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });

    }
    const findToAccount = async () => {
        if (toAccNo === undefined || toAccNo === "") {
            return;
        }
        await postMethod(`${Apiurl.findAccounts}/${toAccNo}`)
            .then((res) => {
                if (res.status === 404) {
                    setValue("to_name", res.data?.account?.name);
                    setError("to_name", { type: "custom", message: "" })
                } else if (res.status === 403) {
                    setValue("to_name", res.data.account?.name);
                    setError("to_name", { type: "custom", message: "" })
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });

    }

    const setEditModal = (id) => {

        setError("from_account_no", { type: "custom", message: "" })
        setError("from_name", { type: "custom", message: "" })
        setError("to_account_no", { type: "custom", message: "" })
        setError("to_name", { type: "custom", message: "" })
        setError("user_id", { type: "custom", message: "" })
        setError("mt5_usdt", { type: "custom", message: "" })
        setError("date", { type: "custom", message: "" })

        const selected1 = tableData.find((item) => item.id === id);
        setSelected(selected1.id);
        setModal(true);

        setValue('from_account_no', selected1.from_account_no);
        setValue('from_name', selected1.from_terminal.name);
        setValue('to_account_no', selected1.to_account_no);
        setValue('to_name', selected1.to_terminal.name);
        setValue('user_id', selected1.user_id);
        setValue('mt5_usdt', atob(selected1.mt5_usdt));
        setValue('date', selected1.date);
        setValue('note', selected1.note);
    };

    const handleEditSubmit = async (data) => {
        data.from_account_no = parseInt(data.from_account_no);
        data.to_account_no = parseInt(data.to_account_no);
        data.mt5_usdt = parseInt(data.mt5_usdt);
        data.user_id = parseInt(data.user_id)

        await putMethod(`${Apiurl.mt5_usdt}/${selected}`, data)
            .then((res) => {
                reset()
                setModal(false)
                getDepositWithdrawalList()
            })
            .catch((error) => {
                // setModal(false)
                console.log('error: ', error)
            });
    }


    return (

        <div>
            <Navbar />
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)} className="d-block">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <h1 style={{ width: "270px" }}>Update Transaction</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <div className="">
                                    <div className="body">
                                        <form onSubmit={handleSubmit(handleEditSubmit)}>

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    From Mt5 Account
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    {...register("from_account_no", {
                                                        required: "From Account Number is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="from_account_no"
                                                    id="from_account_no"
                                                    placeholder="Enter From Account Number"
                                                    onChange={handleFromAccount}
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.from_account_no && (
                                                <p className="useredit_err">{errors.from_account_no.message}</p>
                                            )}


                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    From Account Name
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    {...register("from_name", {
                                                        required: "From Account Name is Required.",
                                                    })}
                                                    name="from_name"
                                                    id="from_name"
                                                    placeholder="Enter From Account Name"
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.from_name && (
                                                <p className="useredit_err">{errors.from_name.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    To Mt5 Account
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    {...register("to_account_no", {
                                                        required: "To Account Number is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="to_account_no"
                                                    id="to_account_no"
                                                    placeholder="Enter To Account Number"
                                                    onChange={handleToAccount}
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.to_account_no && (
                                                <p className="useredit_err">{errors.to_account_no.message}</p>
                                            )}


                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    To Account Name
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    {...register("to_name", {
                                                        required: "To Account Name is Required.",
                                                    })}
                                                    name="to_name"
                                                    id="to_name"
                                                    placeholder="Enter To Acount Name"
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.to_name && (
                                                <p className="useredit_err">{errors.to_name.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Fund User
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <select
                                                    id="user_id"
                                                    name="user_id"
                                                    {...register("user_id", {
                                                        required: "User is Required.",
                                                    })}
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12  mt-1"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="">Select User...</option>
                                                    {users.map((user, i) => (
                                                        <option key={i} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.user_id && (
                                                <p className="useredit_err">{errors.user_id.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    MT5 USDT
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="number"
                                                    {...register("mt5_usdt", {
                                                        required: "MT5 USDT is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="mt5_usdt"
                                                    id="mt5_usdt"
                                                    placeholder="Enter MT5 USDT Number"
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.mt5_usdt && (
                                                <p className="useredit_err">{errors.mt5_usdt.message}</p>
                                            )}



                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Date
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="date"
                                                    {...register("date", {
                                                        required: "Date is Required.",
                                                    })}
                                                    name="date"
                                                    id="date"
                                                    // defaultValue={defaultDate}
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.date && (
                                                <p className="useredit_err">{errors.date.message}</p>
                                            )}



                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Note
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    {...register("note")}
                                                    name="note"
                                                    id="note"
                                                    placeholder="Enter Note"
                                                    className="form-control input-height col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {/* {errors.note && (
                                                <p className="useredit_err">{errors.note.message}</p>
                                            )} */}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <div className="col-12 text-center mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success btn_bg_color"
                                                        style={{ marginRight: "30px" }}
                                                    >
                                                        Submit
                                                    </button>
                                                    <div
                                                        className="btn btn-secondary"
                                                        style={{ backgroundColor: "grey" }}
                                                        onClick={() => {
                                                            setModal(false);
                                                            // setErrors({});
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalHeader>
            </Modal>
            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1>Transaction List</h1>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                                    <div className="mb-3 mb-xl-0">
                                        <Link to="/addtransfer">
                                            <span className="btn btn-dark">Add transaction</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="body">

                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                            value={searchComp}
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="search-mail">
                                                <i className="fa fa-search text-info"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="body">
                                    <label style={{ fontWeight: 500 }}>Filter By Date : </label>
                                    <form>
                                        {/* <label style={{marginBottom:"0px"}}><span style={{marginLeft:'10px',paddingTop:'10px'}} >Start Date </span><span style={{marginLeft:'80px'}}>End Date </span></label> */}
                                        <div className="input-group flex-sm-nowrap flex-wrap align-items-center" style={{ gap: "10px" }}>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">From:</p>
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    style={{ margin: "0px 5px 0px 5px" }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.start_date}
                                                    className="form-control"
                                                    placeholder="Start Date..."
                                                />
                                            </div>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">To:</p>
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    style={{ marginLeft: '5px' }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.end_date}
                                                    className="form-control"
                                                    placeholder="End Date..."
                                                />
                                            </div>
                                            <div className="ml-2" style={{ cursor: 'pointer' }}>
                                                <span className="input-group-text" id="search-mail" onClick={handleFilteredResult} >
                                                    <i className="fa fa-search text-info"></i>
                                                </span>
                                            </div>
                                            {/* <div className="" style={{ margin: '8px 0px 0px 10px' }}>
                                                <span className="input-group-text" id="search-mail" onClick={handleExcelSheet} >
                                                    <i className="fa fa-download"></i>
                                                </span>
                                            </div> */}
                                        </div>
                                        {dateError && <p style={{ color: "red", fontSize: "13px", marginLeft: "5px" }}>{dateError}</p>}
                                    </form>
                                </div>
                            </div>

                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                <>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Date</th>
                                                    <th>From Account</th>
                                                    <th>To Account</th>
                                                    <th>MT5_USDT</th>
                                                    <th>User</th>
                                                    <th>Transaction By</th>
                                                    <th>Note</th>
                                                    {(isAdmin ||
                                                        rolePermissions?.edit ||
                                                        rolePermissions?.delete) && <th>Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableData.length > 0 ? (
                                                    tableData.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>#{(currentPage - 1) * limit + 1 + i} </td>
                                                                <td>{list?.date}</td>
                                                                <td>{list?.from_account_no}</td>
                                                                <td>{list?.to_account_no}</td>
                                                                <td>{atob(list?.mt5_usdt)}</td>
                                                                <td>{list?.users?.name}</td>
                                                                <td>{list?.transaction_by_users?.name ? list?.transaction_by_users?.name : "-"}</td>
                                                                <td style={{ whiteSpace: "break-spaces" }}>{list?.note ? list.note : "-"}</td>
                                                                <td>
                                                                    {(isAdmin || rolePermissions?.edit) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Edit"
                                                                            onClick={() => setEditModal(list.id)}
                                                                        >
                                                                            <i className="fa fa-pencil text-success edit-button"></i>
                                                                        </button>
                                                                    )}
                                                                    {(isAdmin || rolePermissions?.delete) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Delete"
                                                                            onClick={() => handleDelete(list.id)}
                                                                        >
                                                                            <i className="fa fa-trash-o text-danger delete-button"></i>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))) : (
                                                    <tr>
                                                        <td style={{ borderTop: "none", textAlign: "center" }} colSpan={9}>No records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {/* <tfoot>
                                                <tr>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            </tfoot> */}
                                        </table>
                                    </div>
                                    {tableData.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                            limit={limit}
                                            onLimitChange={handleItemsPerPageChange}
                                        />
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AllTransferList;
