import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "../Navbar";
import { postMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import "../../../css/sidebar.css"

const BuySell = () => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();

  const [currencies, setCurrencies] = useState([])
  const [users, setUsers] = useState([])

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const defaultDate = getCurrentDate();

  const handleBuySell = async (data) => {
    data.mt5_usdt = 0
    // const URL = data.transaction_type === "buy" ? `/buy_currency` : `/sell_currency`
    await postMethod(Apiurl.currency_buy_sell, data).then((data) => {
      reset()
      // setValue("user_id", "");
      // setValue("currency_id", "");
      // setValue("rate", "");
    }).catch((error) => {
    })
  }



  const fromAmount = watch("from_amount");
  const toAmount = watch("to_amount");
  const fromCurrencyId = watch("from_currency_id");
  const toCurrencyId = watch("to_currency_id");

  useEffect(() => {
    if (fromAmount && toAmount) {
      const calculatedRate = parseFloat(toAmount) / parseFloat(fromAmount);
      setValue("rate", calculatedRate.toFixed(4));
    } else {
      setValue("rate", "");
    }
  }, [fromAmount, toAmount, setValue]);

  useEffect(() => {
    getCurrencies();
    getUsers();
  }, []);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Buy/Sell Currency</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleBuySell)}>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        User
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="user_id"
                          name="user_id"
                          className="form-control"
                          {...register("user_id", {
                            required: "User is Required.",
                          })}
                        >
                          <option value="" disabled>
                            Select User...
                          </option>
                          {users.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.user_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.user_id.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        From Currency Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="from_currency_id"
                          name="from_currency_id"
                          className="form-control"
                          {...register("from_currency_id", {
                            required: "Currency Type is Required.",
                          })}
                        >
                          <option value="" disabled>
                            Select Currency Type...
                          </option>
                          {currencies.map((item, i) => (
                            <option key={i} value={item.id} disabled={item.id == toCurrencyId}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.from_currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.from_currency_id.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        From Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("from_amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          name="from_amount"
                          id="from_amount"
                          // value={watch("amount")}
                          placeholder="Enter Amount"
                        />
                        {errors.from_amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.from_amount.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        To Currency Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="to_currency_id"
                          name="to_currency_id"
                          className="form-control"
                          {...register("to_currency_id", {
                            required: "Currency Type is Required.",
                          })}

                        >
                          <option value="" disabled>
                            Select Currency Type...
                          </option>
                          {currencies.map((item, i) => (
                            <option key={i} value={item.id} disabled={item.id == fromCurrencyId}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {errors.to_currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.to_currency_id.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        To Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("to_amount", {
                            required: "Amount is Required.",
                          })}
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                          name="to_amount"
                          id="to_amount"
                          // value={watch("amount")}
                          placeholder="Enter Amount"
                        />
                        {errors.to_amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.to_amount.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          name="date"
                          className="form-control"
                          id="date"
                          // placeholder="Enter Date"
                          defaultValue={defaultDate}
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Rate
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("rate", {
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message: "Please enter a valid rate.",
                            },
                          })}
                          className="form-control"
                          name="rate"
                          id="inputEmail3"
                          disabled
                          placeholder="Rate"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("note")}
                          className="form-control"
                          name="note"
                          id="note"
                          placeholder="Enter Note"
                        />

                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySell;
