import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Navbar from "../Navbar";
import { Modal, ModalHeader } from "reactstrap";
import { getMethod, putMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import Pagination from "../../../services/Pagination";
import { debounce } from "lodash";

const DueBills = () => {
  const [bills, setBills] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const [modal, setModal] = useState(false);
  const [balance, setBalance] = useState([]);
  const [id, setId] = useState(0);
  const [agencies, setAgencies] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [agencyId, setAgencyId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [limit, setLimit] = useState(50);

  const getAgencies = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.agency)
      .then((res) => {
        setAgencies(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const getDueBills = async () => {
    setIsLoading(true);
    await getMethod(`${Apiurl.dueIncome}?agency_id=${agencyId}`, {
      page: currentPage,
      pageSize: 8,
      search: searchQuery,
    })
      .then((res) => {
        setBills(res.data.data);
        setBalance(res.data.balance);
        setTotalPages(res.data.total_page);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
      .then((res) => {
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const dueIncome = permissions.find(
    (item) => item.table_name === "due_income"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  const setEditModal = (id) => {
    setModal(true);
    const selected = bills.find((item) => item.id === id);
    setId(selected.id);
  };

  const handleUpdateDueBills = async (data) => {
    try {
      data.adjustment_amount = parseInt(data.adjustment_amount, 10);
      data.credit_from = parseInt(data.credit_from, 10);
      const response = await putMethod(`${Apiurl.dueIncome}?id=${id}`, data);
      reset();
      setModal(!modal);
      getDueBills();
    } catch (err) {
    }
  };

  const delayedSearch = debounce(() => {
    getDueBills();
  }, 300);

  useEffect(() => {
    getAgencies();
    getUsers();
  }, []);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, agencyId, limit]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Due Bills</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleSubmit(handleUpdateDueBills)}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Received Amount
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="number"
                            style={{ width: "300px" }}
                            {...register("adjustment_amount", {
                              required: "Received Amount is Required.",
                            })}
                            className="form-control"
                            name="adjustment_amount"
                            placeholder="Enter Received Amount"
                          />
                          {errors.adjustment_amount && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.adjustment_amount.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Received By
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <select
                            name="credit_from"
                            className="form-control input-height"
                            style={{ width: "300px" }}
                            {...register("credit_from", {
                              required: "Received by is Required.",
                            })}
                          >
                            <option value="" disabled selected>
                              Select Receiver for this Bill...
                            </option>
                            {users.map((user, index) => (
                              <option key={index} value={user.id}>
                                {user.name}
                              </option>
                            ))}
                          </select>
                          {errors.credit_from && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.credit_from.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Date
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="date"
                            style={{ width: "300px" }}
                            {...register("date", {
                              required: "Date is Required.",
                            })}
                            className="form-control"
                            name="date"
                          />
                          {errors.date && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.date.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Payment Type
                          <span
                            className="text-danger"
                            style={{ margin: "4px" }}
                          >
                            *
                          </span>
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <select
                            name="payment_type"
                            className="form-control input-height"
                            style={{
                              width: "300px",

                              marginTop: "10px",
                            }}
                            {...register("payment_type", {
                              required: "Payment Type Required.",
                            })}
                          >
                            <option value="" disabled selected>
                              Select Payment Type...
                            </option>
                            <option key="cash" value="cash">
                              Cash
                            </option>
                            <option key="bank" value="bank">
                              Bank
                            </option>
                          </select>
                          {errors.payment_type && (
                            <p style={{ color: "red", fontSize: "13px" }}>
                              {errors.payment_type.message}
                            </p>
                          )}
                        </div>
                      </div>
                      {watch("payment_type") === "bank" && (
                        <div className="form-group row">
                          <label
                            className="col-sm-2 col-form-label"
                            style={{ marginRight: "50px" }}
                          >
                            Note
                            <span
                              className="text-danger"
                              style={{ margin: "4px" }}
                            >
                              *
                            </span>
                          </label>
                          <div className="col-md-6 col-sm-10">
                            <input
                              type="note"
                              {...register("note", {
                                required: "Note is Required.",
                              })}
                              className="form-control"
                              name="note"
                              placeholder="Enter Note"
                              style={{ width: "300px" }}
                            />
                            {errors.note && (
                              <p style={{ color: "red", fontSize: "13px" }}>
                                {errors.note.message}
                              </p>
                            )}
                          </div>
                        </div>
                      )}

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "20px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              reset();
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Due Bills</h1>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  {balance.map((item, i) => (
                    <div key={i} className="mb-3 mb-xl-0" style={{ margin: "10px" }}>
                      <span className="btn btn-dark">Pending {item.currency_names}</span>
                      <span className="form-control">{item.totalPendingAmount}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form>
                    <div className="input-group">
                      <select
                        value={agencyId}
                        name="agency_id"
                        onChange={(e) => setAgencyId(e.target.value)}
                        className="form-control input-height"
                      >
                        <option value="">Select Agency Name...</option>
                        {agencies &&
                          agencies.map((agency, index) => (
                            <option key={index} value={agency.id}>
                              {agency.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </form>
                </div>
              </div>

              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Agency Name</th>
                          <th>Date</th>
                          <th>Bill No.</th>
                          <th>Bill Amount</th>
                          <th>Received Amount</th>
                          <th>Note</th>
                          {(isAdmin || dueIncome?.edit) && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {bills.length > 0 ?
                          bills.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{i + 1}</td>
                                <td>{list.agency.name}</td>
                                <td>{list.date}</td>
                                <td>{list.bill_no}</td>
                                <td> {list.amount ? atob(list.amount) : ""}</td>
                                <td>
                                  {list.adjustment_amount
                                    ? atob(list.adjustment_amount)
                                    : ""}
                                </td>
                                <td>{list.note}</td>
                                {(isAdmin || dueIncome?.edit) && (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          ))
                          : (<tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={14}>No records found</td>
                          </tr>)}
                      </tbody>
                    </table>
                  </div>
                  {bills.length > 0 &&
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DueBills;
