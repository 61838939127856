import React, { useEffect, useRef, useState } from 'react';
import { deleteMethod, getMethod, postMethod, putMethod } from '../../../services/base_service';
import { debounce } from 'lodash';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../../../services/Pagination';
import { Apiurl } from '../../../services/apiRoute';
import { useForm } from 'react-hook-form';
import { Modal, ModalHeader } from 'reactstrap';
import { showConfirmationDialog } from '../../../services/alert_service';

const AllCreditInOutList = () => {

    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [inputTimeout, setInputTimeout] = useState(null);
    const [searchComp, setSearchComp] = useState("");
    const [dateRange, setDateRange] = useState({
        start_date: "",
        end_date: "",
    });
    const [dateError, setDateError] = useState("");
    const [users, setUsers] = useState([])
    const [modal, setModal] = useState(false);
    const [selected, setSelected] = useState({});
    const [accNo, setAccNo] = useState("")
    const [order, setOrder] = useState("")
    const [limit, setLimit] = useState(50);
    const [totalSum, setTotalSum] = useState(0);

    // const [excelPath, setExcelPath] = useState("");

    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const defaultDate = getCurrentDate();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        setError,
    } = useForm();

    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const rolePermissions = permissions.find(
        (item) => item.table_name === "credit_in_out"
    );
    const isAdmin = localStorage.getItem("isAdmin") === "true";


    const getDepositWithdrawalList = async () => {
        try {
            setIsLoading(true);
            const response = await getMethod(`${Apiurl.credit_get_list}?page=${currentPage}&limit=${limit}&search=${searchQuery}&account_no=${order}`);
            setTotalSum(response.data.total_sum);
            setTableData(response.data.data);
            setTotalPages(response.data.total_page);
            setIsLoading(false);
        } catch (error) {

        } finally {
            setIsLoading(false);
        }
    };

    const handleOrder = () => {
        setOrder(order === "DESC" ? "ASC" : "DESC");
    }

    const getUsers = async () => {
        await getMethod(`${Apiurl.filter_user_list}`)
            .then((res) => {
                // console.log('res>>>>: ', res.data.data)
                // const filtreredUsers = res.data.data.filter((user) => user.role.role !== "MANAGER (STAFF)")
                // console.log('filtreredUsers: ', filtreredUsers)
                setUsers(res.data.data);
            })
            .catch((error) => {
            });
    };
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event) => {
        setLimit(Number(event.target.value));
    };

    const delayedSearch = debounce(() => {
        getDepositWithdrawalList();
    }, 300);

    useEffect(() => {
        delayedSearch();
    }, [currentPage, searchQuery, order, limit]);



    const handleAccount = (e) => {
        setError("account_no", { type: "custom", message: "" })
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setAccNo(e.target.value);
            }, 500)
        );
    }

    const delayedSearchAccount = debounce(() => {
        findAccount();
    }, 500);

    const findAccount = async () => {
        if (accNo === undefined || accNo === "") {
            return;
        }
        await postMethod(`${Apiurl.findAccounts}/${accNo}`)
            .then((res) => {
                if (res.status === 404) {
                    setValue("name", res.data?.account?.name);
                    setError("name", { type: "custom", message: "" })
                    setAccNo("")
                } else if (res.status === 403) {
                    setValue("name", res.data.account?.name);
                    setError("name", { type: "custom", message: "" })
                    setAccNo("")
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });
    }


    const setEditModal = (id) => {

        setError("transaction_type", { type: "custom", message: "" })
        setError("account_no", { type: "custom", message: "" })
        setError("name", { type: "custom", message: "" })
        setError("user_id", { type: "custom", message: "" })
        setError("mt5_usdt", { type: "custom", message: "" })
        setError("date", { type: "custom", message: "" })

        const selected1 = tableData.find((item) => item.id === id);
        setSelected(selected1.id);
        setModal(true);

        setValue('transaction_type', selected1.type === "Cr" ? "credit_in" : "credit_out");
        setValue('account_no', selected1.account_no);
        setValue('name', selected1.terminal.name);
        setValue('user_id', selected1.user_id);
        setValue('mt5_usdt', selected1.mt5_usdt);
        setValue('date', selected1.date);
        setValue('note', selected1.note);
    };

    const handleEditSubmit = async (data) => {
        // data.account_no = parseInt(data.account_no);
        // data.user_id = parseInt(data.user_id)
        data.isCreditInOut = data.transaction_type === 'credit_in' ? 1 : 0;

        delete data['transaction_type'];

        await putMethod(`${Apiurl.credit_in_out}/${selected}`, data)
            .then((res) => {
                reset()
                setModal(false)
                // getCreditList()
            })
            .catch((error) => {
                // setModal(false)
                console.log('error: ', error)
            });
    }

    const handleDelete = async (id) => {
        try {
            const confirmation = await showConfirmationDialog(
                "Are you sure you want to delete ?",
                "",
                "warning"
            );
            if (confirmation) {
                await deleteMethod(`${Apiurl.credit_in_out}/${id}`)
                    .then((res) => {
                        // getCreditList();
                    })
                    .catch((error) => {
                    });
            }
        } catch (error) {
        }
    };

    const handleSearch = (search) => {
        setSearchComp(search);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setSearchQuery(search);
                setCurrentPage(1);
                setDateRange({ start_date: "", end_date: "" })
                setDateError("")
            }, 500)
        );
    };

    const handleFilterDate = (e) => {
        setDateRange({ ...dateRange, [e.target.name]: e.target.value });
        setDateError("");
    }

    const handleFilteredResult = async () => {
        try {
            setIsLoading(true);
            // if (dateRange.start_date && dateRange.end_date && dateRange.end_date >= dateRange.start_date) {
            const response = await getMethod(`${Apiurl.credit_get_list}?page=${currentPage}&limit=${limit}&search=${searchQuery}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}&account_no=${order}`);
            setTotalSum(response.data.total_sum);
            setTableData(response.data.data);
            setTotalPages(response.data.total_page);
            // } else {
            //     setDateError("Please select proper date range")
            // }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleExcelSheet = async () => {
        try {
            setIsLoading(true);
            // if (dateRange.start_date && dateRange.end_date && dateRange.end_date >= dateRange.start_date) {
            const response = await postMethod(`${Apiurl.generate_excel}?search=${searchQuery}&start_date=${dateRange.start_date}&end_date=${dateRange.end_date}&account_no=${order}`);
            const path = (Apiurl.routePath.replace('/api/v1', '') + response.data.file_name)
            generateExcel(path, "file.xlsx")
            // } else {
            //     setDateError("Please select proper date range")
            // }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        delayedSearchAccount()
    }, [accNo]);

    const generateExcel = async (path, name) => {
        window.open(path)
    }

    return (
        <div>
            <Navbar />
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)} className="d-block">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <h1 style={{ width: "270px" }}>Update Credit In/Out</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <div className="">
                                    <div className="body">
                                        <form onSubmit={handleSubmit(handleEditSubmit)}>

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Transaction Type
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <select
                                                    id="transaction_type"
                                                    name="transaction_type"
                                                    {...register("transaction_type", {
                                                        required: "Transaction Type is Required.",
                                                    })}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="" disabled >Select Transaction Type...</option>
                                                    <option value="credit_in">Credit In</option>
                                                    <option value="credit_out">Credit Out</option>
                                                </select>
                                            </div>
                                            {errors.transaction_type && (
                                                <p className="useredit_err">{errors.transaction_type.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Fund User
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <select
                                                    id="user_id"
                                                    name="user_id"
                                                    {...register("user_id", {
                                                        required: "User is Required.",
                                                    })}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="">Select User...</option>
                                                    {users.map((user, i) => (
                                                        <option key={i} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.user_id && (
                                                <p className="useredit_err">{errors.user_id.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Account Number
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="number"
                                                    {...register("account_no", {
                                                        required: "Account Number is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="account_no"
                                                    id="account_no"
                                                    placeholder="Enter Account Number"
                                                    onChange={handleAccount}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.account_no && (
                                                <p className="useredit_err">{errors.account_no.message}</p>
                                            )}


                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Name
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    {...register("name", {
                                                        required: "Name is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="name"
                                                    id="name"
                                                    placeholder="Enter Name"
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.name && (
                                                <p className="useredit_err">{errors.name.message}</p>
                                            )}



                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    MT5 USDT
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="number"
                                                    {...register("mt5_usdt", {
                                                        required: "MT5 USDT is Required.",
                                                    })}
                                                    onWheel={(e) => e.target.blur()}
                                                    name="mt5_usdt"
                                                    id="mt5_usdt"
                                                    placeholder="Enter MT5 USDT Number"
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.mt5_usdt && (
                                                <p className="useredit_err">{errors.mt5_usdt.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Date
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="date"
                                                    {...register("date", {
                                                        required: "Date is Required.",
                                                    })}
                                                    name="date"
                                                    id="date"
                                                    // defaultValue={defaultDate}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>
                                            {errors.date && (
                                                <p className="useredit_err">{errors.date.message}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Note
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    {...register("note")}
                                                    name="note"
                                                    id="note"
                                                    placeholder="Enter Note"
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                </input>
                                            </div>

                                            <div className="form-group row justify-content-between align-items-center">
                                                <div className="col-12 text-center mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success btn_bg_color"
                                                        style={{ marginRight: "30px" }}
                                                    >
                                                        Submit
                                                    </button>
                                                    <div
                                                        className="btn btn-secondary"
                                                        style={{ backgroundColor: "grey" }}
                                                        onClick={() => {
                                                            setModal(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalHeader>
            </Modal>
            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1>All Credit In Out List</h1>
                            </div>
                            <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                                    <div className="mb-3 mb-xl-0">
                                        <Link to="/credit-in-out">
                                            <span className="btn btn-dark">Credit In/Out</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="body">

                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            onChange={(e) => { handleSearch(e.target.value); }}
                                            value={searchComp}
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="search-mail">
                                                <i className="fa fa-search text-info"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="body">
                                    <label style={{ fontWeight: 500 }}>Filter By Date : </label>
                                    <form>
                                        {/* <label style={{marginBottom:"0px"}}><span style={{marginLeft:'10px',paddingTop:'10px'}} >Start Date </span><span style={{marginLeft:'80px'}}>End Date </span></label> */}
                                        <div className="input-group flex-sm-nowrap flex-wrap align-items-center" style={{ gap: "10px" }}>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">From:</p>
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    style={{ margin: "0px 4px 0px 5px", height: "40px" }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.start_date}
                                                    max={dateRange?.end_date || defaultDate}
                                                    className="form-control"
                                                    placeholder="Start Date..."
                                                />
                                            </div>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">To:</p>
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    style={{ marginLeft: '5px', height: "40px" }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.end_date}
                                                    min={dateRange?.start_date}
                                                    max={defaultDate}
                                                    className="form-control"
                                                    placeholder="End Date..."
                                                />
                                            </div>
                                            <div className="ml-2" style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="bottom" title="Search">
                                                <span className="input-group-text" id="search-mail" onClick={handleFilteredResult} >
                                                    <i className="fa fa-search text-info"></i>
                                                </span>
                                            </div>
                                            {/* <div className="" style={{ margin: '4px 0px 0px 10px', cursor: 'pointer' }} data-toggle="tooltip" data-placement="bottom" title="Download">
                                                <span className="input-group-text" id="search-mail" onClick={handleExcelSheet} >
                                                    <i className="fa fa-download"></i>
                                                </span>
                                            </div> */}
                                        </div>
                                        {dateError && <p style={{ color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "0px" }}>{dateError}</p>}
                                    </form>
                                </div>
                            </div>

                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                <>

                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>

                                                    <th>Sr No.</th>
                                                    <th style={{ cursor: "pointer" }} onClick={() => setOrder("")}>Date
                                                        <i className="fa fa-sort-numeric-asc text-white" aria-hidden="true"></i>
                                                    </th>
                                                    <th style={{ cursor: "pointer" }} onClick={handleOrder}>Account No {" "}
                                                        <i className="fa fa-sort-numeric-asc text-white" aria-hidden="true"></i>
                                                    </th>
                                                    <th>Terminal name</th>
                                                    <th>MT5 USDT</th>
                                                    <th>Type</th>
                                                    <th>User name</th>
                                                    <th>Cr/Dr By</th>
                                                    <th>Note</th>
                                                    {(isAdmin ||
                                                        rolePermissions?.edit ||
                                                        rolePermissions?.delete) && <th>Action</th>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {tableData.length > 0 ? (
                                                    tableData.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>#{(currentPage - 1) * limit + 1 + i} </td>
                                                                <td>{list?.date || "-"}</td>
                                                                <td>{list?.account_no}</td>
                                                                <td>{list?.terminal?.name}</td>
                                                                <td>{list?.mt5_usdt}</td>
                                                                <td>{list?.type}</td>
                                                                <td>{list?.users?.name}</td>
                                                                <td>{list?.transaction_by_users?.name || "-"}</td>
                                                                <td style={{ whiteSpace: "break-spaces" }}>{list?.note || "-"}</td>
                                                                <td>
                                                                    {(isAdmin || rolePermissions?.edit) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Edit"
                                                                            onClick={() => setEditModal(list.id)}
                                                                        >
                                                                            <i className="fa fa-pencil text-success edit-button"></i>
                                                                        </button>
                                                                    )}
                                                                    {(isAdmin || rolePermissions?.delete) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Delete"
                                                                            onClick={() => handleDelete(list.id)}
                                                                        >
                                                                            <i className="fa fa-trash-o text-danger delete-button"></i>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))) : (
                                                    <tr>
                                                        <td style={{ borderTop: "none", textAlign: "center" }} colSpan={10}>No records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {tableData.length > 0 ? (
                                                <tfoot>
                                                    <tr>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td style={{ borderTop: '1px solid black', fontWeight: 'bold', color: (totalSum > 0) ? "green" : "red", }}>
                                                            {parseFloat(totalSum).toFixed(2)}
                                                        </td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                    </tr>
                                                </tfoot>) : <></>}
                                        </table>
                                    </div>
                                    {tableData.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                            limit={limit}
                                            onLimitChange={handleItemsPerPageChange}
                                        />
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default AllCreditInOutList;
