import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "../Navbar";
import { postMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import debounce from "lodash/debounce";
import "../../../css/sidebar.css";

const AddTransfer = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        setError,
        watch,
    } = useForm({});

    const [users, setUsers] = useState([])
    const [inputTimeout, setInputTimeout] = useState(null);
    const [fromAccNo, setFromAccNo] = useState("")
    const [toAccNo, setToAccNo] = useState("")

    const getUsers = async () => {
        await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((error) => {
            });
    };

    const handleDepositWithdraw = async (data) => {
        data.from_account_no = parseInt(data.from_account_no);
        data.to_account_no = parseInt(data.to_account_no);
        data.mt5_usdt = parseInt(data.mt5_usdt);
        data.user_id = parseInt(data.user_id)

        // delete data['from_name'];
        // delete data['to_name'];
        await postMethod(Apiurl.mt5_usdt_add, data).then((res) => {
            reset()
            setValue("user_id", "");
        }).catch((error) => {
            console.log('error: ', error)
        })
    }


    const handleFromAccount = (e) => {
        setError("from_account_no", { type: "custom", message: "" })
        // setError("from_name", { type: "custom", message: "" })
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setFromAccNo(e.target.value);
            }, 500)
        );
    }

    const handleToAccount = (e) => {
        setError("to_account_no", { type: "custom", message: "" })
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setToAccNo(e.target.value);
            }, 500)
        );
    }

    const delayedSearchFrom = debounce(() => {
        findFromAccount();
    }, 500);

    const delayedSearchTo = debounce(() => {
        findToAccount();
    }, 500);

    useEffect(() => {
        delayedSearchFrom()
    }, [fromAccNo]);

    useEffect(() => {
        delayedSearchTo()
    }, [toAccNo]);

    const findFromAccount = async () => {
        if (fromAccNo === undefined || fromAccNo === "") {
            return;
        }
        await postMethod(`${Apiurl.findAccounts}/${fromAccNo}`)
            .then((res) => {
                if (res.status === 404) {
                    setValue("from_name", res.data?.account?.name);
                    setError("from_name", { type: "custom", message: "" })
                } else if (res.status === 403) {
                    setValue("from_name", res.data.account?.name);
                    setError("from_name", { type: "custom", message: "" })
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });

    }
    const findToAccount = async () => {
        if (toAccNo === undefined || toAccNo === "") {
            return;
        }
        await postMethod(`${Apiurl.findAccounts}/${toAccNo}`)
            .then((res) => {
                if (res.status === 404) {
                    setValue("to_name", res.data?.account?.name);
                    setError("to_name", { type: "custom", message: "" })
                } else if (res.status === 403) {
                    setValue("to_name", res.data.account?.name);
                    setError("to_name", { type: "custom", message: "" })
                }
            })
            .catch((error) => {
                console.log('error: ', error)
            });

    }

    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const defaultDate = getCurrentDate();

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div>
            <div>
                <Navbar />
                <div id="main-content">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <h1>Add Transaction</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="body">
                                        <form onSubmit={handleSubmit(handleDepositWithdraw)}>

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    From Mt5 Account
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="number"
                                                        {...register("from_account_no", {
                                                            required: "From Account Number is Required.",
                                                        })}
                                                        onWheel={(e) => e.target.blur()}
                                                        className="form-control"
                                                        name="from_account_no"
                                                        id="from_account_no"
                                                        placeholder="Enter From Account Number"
                                                        onChange={handleFromAccount}
                                                    />
                                                    {errors.from_account_no && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.from_account_no.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    From Account Name
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="text"
                                                        {...register("from_name", {
                                                            required: "From Account Name is Required.",
                                                        })}
                                                        className="form-control"
                                                        name="from_name"
                                                        id="from_name"
                                                        placeholder="Enter From Account Name"
                                                    />
                                                    {errors.from_account_name && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.from_account_name.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    To Mt5 Account
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="number"
                                                        {...register("to_account_no", {
                                                            required: "To Account Number is Required.",
                                                        })}
                                                        onWheel={(e) => e.target.blur()}
                                                        className="form-control"
                                                        name="to_account_no"
                                                        id="to_account_no"
                                                        placeholder="Enter To Account Number"
                                                        onChange={handleToAccount}
                                                    />
                                                    {errors.to_account_no && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.to_account_no.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    To Account Name
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="text"
                                                        {...register("to_name", {
                                                            required: "To Account Name is Required.",
                                                        })}
                                                        className="form-control"
                                                        name="to_name"
                                                        id="to_name"
                                                        placeholder="Enter To Acount Name"
                                                    />
                                                    {errors.to_account_name && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.to_account_name.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    User
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <select defaultValue={""}
                                                        id="user_id"
                                                        name="user_id"
                                                        className="form-control"
                                                        {...register("user_id", {
                                                            required: "User is Required.",
                                                        })}
                                                    >
                                                        <option value="" disabled>
                                                            Select User...
                                                        </option>
                                                        {users.map((item) => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.user_id && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.user_id.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    MT5 USDT
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="number"
                                                        {...register("mt5_usdt", {
                                                            required: "MT5 USDT is Required.",
                                                        })}
                                                        onWheel={(e) => e.target.blur()}
                                                        className="form-control"
                                                        name="mt5_usdt"
                                                        id="mt5_usdt"
                                                        placeholder="Enter MT5 USDT"
                                                    />
                                                    {errors.mt5_usdt && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.mt5_usdt.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>



                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    Date
                                                    <span className="text-danger" style={{ margin: "4px" }}>
                                                        *
                                                    </span>
                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="date"
                                                        {...register("date", {
                                                            required: "Date is Required.",
                                                        })}
                                                        name="date"
                                                        className="form-control"
                                                        id="date"
                                                        // placeholder="Enter Date"
                                                        defaultValue={defaultDate}
                                                    />
                                                    {errors.date && (
                                                        <p
                                                            style={{
                                                                color: "red",
                                                                marginBottom: "0px",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {errors.date.message}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label">
                                                    Note

                                                </label>
                                                <div className="col-md-6 col-sm-10">
                                                    <input
                                                        type="text"
                                                        {...register("note")}
                                                        className="form-control"
                                                        name="note"
                                                        id="note"
                                                        placeholder="Enter Note"
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-2 col-form-label"></label>

                                                <div className="col-md-6 col-sm-10">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success btn_bg_color"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddTransfer;
