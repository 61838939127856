import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import Navbar from "../Navbar";
import { postMethod, getMethod } from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import "../../../css/sidebar.css";
import debounce from "lodash/debounce";
import { useLocation } from "react-router-dom";

const DepositWithdraw = () => {

  const location = useLocation()
  // const data = location?.state?.formData
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      // name: 'haresh',
      // date: new Date()
    }
  });

  const [currencies, setCurrencies] = useState([])
  const [users, setUsers] = useState([])
  const [inputTimeout, setInputTimeout] = useState(null);
  const [accNo, setAccNo] = useState("")

  const getCurrencies = async () => {
    await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
      setCurrencies(data.data.currency)
    }).catch((error) => {
    })
  }

  const getUsers = async () => {
    await getMethod(`${Apiurl.filter_user_list}`)
      .then((res) => {
        // console.log('res>>>>: ', res.data.data)
        // const filtreredUsers = res.data.data.filter((user) => user.role.role !== "MANAGER (STAFF)")
        // console.log('filtreredUsers: ', filtreredUsers)
        setUsers(res.data.data);
      })
      .catch((error) => {
      });
  };

  // handle group field

  // const handleGroupField  = (e) => {
  //   const input = e.target.value;
  //   const [name, number, currency] = input.split(' ');
  //   setValue('transaction_id', name);
  //   setValue('account_no', number);

  // }

  // const FillDataForDeposit = () => {
  //   setValue('account_no', data.account_no);
  //   setValue('transaction_type', data.transaction_type ===  "credit_out" ? "deposit" : "");
  //   setValue('name', data.name);
  //   setValue('user_id', data.user_id);
  //   setValue('mt5_usdt', data.mt5_usdt);
  //   // setValue('currency_id', data.currency_id);
  //   setValue('date', data.date);
  //   setValue('note', data.note);
  // }

  // useEffect(() => {
  //   if (data) {
  //     FillDataForDeposit()
  //   }
  // }, [data])

  const handleDepositWithdraw = async (data) => {
    data.account_no = parseInt(data.account_no);
    // data.rate = parseInt(data.rate);
    data.currency_id = parseInt(data.currency_id);
    data.user_id = parseInt(data.user_id)

    const URL = data.transaction_type === "deposit" ? `/add_deposit` : `/add_withdrawals`
    delete data['transaction_type'];
    await postMethod(URL, data).then((res) => {
      reset()
      setValue("transaction_type", "");
      setValue("user_id", "");
      setValue("currency_id", "");
    })
  }

  const handleAccount = (e) => {
    setError("account_no", { type: "custom", message: "" })
    if (inputTimeout) clearTimeout(inputTimeout);
    setInputTimeout(
      setTimeout(() => {
        setAccNo(e.target.value);
      }, 500)
    );
  }

  const delayedSearch = debounce(() => {
    findAccount();
  }, 500);

  const findAccount = async () => {
    if (accNo === undefined || accNo === "") {
      return;
    }
    await postMethod(`${Apiurl.findAccounts}/${accNo}`)
      .then((res) => {
        if (res.status === 404) {
          setValue("name", res.data?.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        } else if (res.status === 403) {
          setValue("name", res.data.account?.name);
          setError("name", { type: "custom", message: "" })
          setAccNo("")
        }
      })
      .catch((error) => {
        console.log('error: ', error)
      });
  }

  // const handleAccountCheck = async (e) => {
  //   setError("account_no", { type: "custom", message: "" })
  //   const value = e.target.value;
  //   delayedSearch(value)
  //   if (value.length > 3) {
  //     await postMethod(`${Apiurl.findAccounts}/${value}`)
  //       .then((res) => {
  //         if (res.status === 404) {
  //           setValue("name", res.data?.account?.name);
  //           setError("name", { type: "custom", message: "" })
  //         } else if (res.status === 403) {
  //           // setUserId(res.data.data.user_id)
  //           setValue("name", res.data.account?.name);
  //           setError("name", { type: "custom", message: "" })
  //         }
  //       })
  //       .catch((error) => {
  //       });
  //   }
  // }


  const handleCurrencyChange = (e) => {
    const selectedCurrencyId = e.target.value;
    setError('currency_id', { type: 'custom', message: "" });
    const selectedCurrency = currencies.find((currency) => currency.id === parseInt(selectedCurrencyId));
    if (selectedCurrency && selectedCurrency.name.toLowerCase() === "usdt") {
      setValue("amount", watch("mt5_usdt"));
      setValue("rate", 1); // Set rate to 1
      document.getElementById("amount").setAttribute("disabled", true);
      setError('amount', { type: 'custom', message: "" });
    } else {
      document.getElementById("amount").removeAttribute("disabled");
    }
  };

  const amountValue = watch("amount");
  const mt5UsdtValue = watch("mt5_usdt");

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const defaultDate = getCurrentDate();

  useEffect(() => {
    if (amountValue && mt5UsdtValue) {
      const calculatedRate = parseFloat(amountValue) / parseFloat(mt5UsdtValue);
      setValue("rate", calculatedRate.toFixed(2));
    } else {
      setValue("rate", "");
    }
  }, [amountValue, mt5UsdtValue, setValue]);


  useEffect(() => {
    getCurrencies();
    getUsers();
  }, []);

  useEffect(() => {
    delayedSearch()
  }, [accNo]);

  return (
    <div>
      <Navbar />
      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Deposit/Withdraw</h1>
              </div>
            </div>
          </div>
          {/* <div className="form-group">
            <label className="font-weight-bold">Group Field</label>
            <input type="text" className="form-control group-input" onChange={handleGroupField}></input>
          </div> */}
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">
                  <form onSubmit={handleSubmit(handleDepositWithdraw)}>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Transaction Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="transaction_type"
                          name="transaction_type"
                          className="form-control"
                          {...register("transaction_type", {
                            required: "Transaction Type is Required.",
                          })}
                        >
                          <option value="" disabled>
                            Select Transaction Type...
                          </option>
                          <option value="deposit">Deposit</option>
                          <option value="withdraw">Withdraw</option>
                        </select>
                        {errors.transaction_type && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.transaction_type.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Transaction Id
                        {/* <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span> */}
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("transaction_id")}
                          className="form-control"
                          name="transaction_id"
                          id="transaction_id"
                          placeholder="Enter Transaction id"
                        />
                        {errors.transaction_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.transaction_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Account Number
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("account_no", {
                            required: "Account Number is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          name="account_no"
                          id="account_no"
                          placeholder="Enter Account Number"
                          onChange={handleAccount}
                        />
                        {errors.account_no && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.account_no.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Name
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("name", {
                            required: "Name is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          name="name"
                          id="name"
                          placeholder="Enter Name"
                        />
                        {errors.name && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.name.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Fund User
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="user_id"
                          name="user_id"
                          className="form-control"
                          {...register("user_id", {
                            required: "User is Required.",
                          })}
                        >
                          <option value="" disabled >
                            Select User...
                          </option>
                          {users.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.user_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.user_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        MT5 USDT
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("mt5_usdt", {
                            required: "MT5 USDT is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          name="mt5_usdt"
                          id="inputEmail3"
                          placeholder="Enter MT5 USDT Number"
                        />
                        {errors.mt5_usdt && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.mt5_usdt.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Currency Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <select defaultValue=""
                          id="currency_id"
                          name="currency_id"
                          className="form-control"
                          {...register("currency_id", {
                            required: "Currency Type is Required.",
                          })}
                          onChange={(e) => {
                            handleCurrencyChange(e);
                          }}
                        >
                          <option value="" disabled >
                            Select Currency Type...
                          </option>
                          {currencies.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                        {errors.currency_id && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.currency_id.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Amount
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="number"
                          {...register("amount", {
                            required: "Amount is Required.",
                          })}
                          onWheel={(e) => e.target.blur()}
                          className="form-control"
                          name="amount"
                          id="amount"
                          placeholder="Enter Amount"
                        />
                        {errors.amount && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.amount.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Date
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="date"
                          {...register("date", {
                            required: "Date is Required.",
                          })}
                          name="date"
                          className="form-control"
                          id="date"
                          // placeholder="Enter Date"
                          defaultValue={defaultDate}
                        />
                        {errors.date && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.date.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Rate
                        <span className="text-danger" style={{ margin: "4px" }}>
                          *
                        </span>
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("rate", {
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message: "Please enter a valid rate.",
                            },
                          })}
                          className="form-control"
                          name="rate"
                          id="inputEmail3"
                          disabled
                          placeholder="Rate"
                        />
                        {errors.rate && (
                          <p
                            style={{
                              color: "red",
                              marginBottom: "0px",
                              fontSize: "13px",
                            }}
                          >
                            {errors.rate.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label">
                        Note
                      </label>
                      <div className="col-md-6 col-sm-10">
                        <input
                          type="text"
                          {...register("note")}
                          className="form-control"
                          name="note"
                          id="note"
                          placeholder="Enter Note"
                        />

                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-2 col-form-label"></label>
                      <div className="col-md-6 col-sm-10">
                        <button
                          type="submit"
                          className="btn btn-success btn_bg_color"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositWithdraw;