import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import {
    getMethod,
    putMethod,
    deleteMethod,
} from "../../services/base_service";
import { Apiurl } from "../../services/apiRoute";
import { Modal, ModalHeader } from "reactstrap";
import Pagination from "../../services/Pagination";
import debounce from "lodash/debounce";
import { Link } from "react-router-dom";
import "../../css/sidebar.css";
import {
    showConfirmationDialog,
} from "../../services/alert_service";

const UserTransferList = () => {

    const [users, setUsers] = useState([]);
    const [currencies, setCurrencies] = useState([])
    const [transferList, setTransferList] = useState([]);
    const [modal, setModal] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [selected, setSelected] = useState(0);
    const [inputTimeout, setInputTimeout] = useState(null);
    const [searchComp, setSearchComp] = useState("");
    const [formValue, setFormValue] = useState({
        from: 0,
        to: 0,
        currency_id: 0,
        payment_type: "cash",
        amount: "",
        date: "",
        note: "",
    });
    const [errors, setErrors] = useState({
        from: "",
        to: "",
        currency_id: "",
        amount: "",
        date: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [limit, setLimit] = useState(50);
    const [selectCurrency, setSelectCurrency] = useState("");
    const [dateRange, setDateRange] = useState({
        start_date: "",
        end_date: "",
    });
    const [dateError, setDateError] = useState("");

    const getCurrentDate = () => {
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, "0");
        const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
        const year = currentDate.getFullYear();
        return `${year}-${month}-${day}`;
    };
    const defaultDate = getCurrentDate();

    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const userPermission = permissions.find(
        (item) => item.table_name === "transfer"
    );
    const isAdmin = localStorage.getItem("isAdmin") === "true";

    const handleChange = (e) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const handleItemsPerPageChange = (event) => {
        setLimit(Number(event.target.value));
    };

    const getUsers = async () => {
        await getMethod(`${Apiurl.filter_user_list}?checkForPermission=false`)
            .then((res) => {
                setUsers(res.data.data);
            })
            .catch((error) => {
            });
    };

    const handleFilterDate = (e) => {
        setDateRange({ ...dateRange, [e.target.name]: e.target.value });
        setDateError("");
    }

    const getCurrencies = async () => {
        await getMethod(`${Apiurl.currency}/list?checkForPermission=false`).then((data) => {
            setCurrencies(data.data.currency)
        }).catch((error) => {
        })
    }

    const getTransferList = async () => {
        setIsLoading(true);
        await getMethod(Apiurl.transfer, {
            page: currentPage,
            limit: limit,
            search: searchQuery,
            currency: selectCurrency,
        })
            .then((res) => {
                setTotalPages(res.data.total_page);
                setTransferList(res.data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const handleFilteredResult = async () => {
        try {
            setIsLoading(true);
            await getMethod(Apiurl.transfer, {
                page: currentPage,
                pageSize: limit,
                search: searchQuery,
                currency: selectCurrency,
                start_date: dateRange.start_date,
                end_date: dateRange.end_date
            })
                .then((res) => {
                    // setTotalSum(res?.data?.total_sum);
                    setTransferList(res.data.data);
                    setTotalPages(res.data.total_page);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const delayedSearch = debounce(() => {
        getTransferList();
    }, 300);

    const validationRules = {
        from: "From User Name is Required",
        to: "To User Name is Required",
        currency_id: "Currency Name is Required",
        amount: "Amount is Required",
        date: "Date is Required",
    };

    const validateForm = () => {
        let formIsValid = true;
        const newErrors = {};
        Object.entries(validationRules).forEach(([key, message]) => {
            if (!formValue[key]) {
                newErrors[key] = message;
                formIsValid = false;
            }
        });

        setErrors(newErrors);
        return formIsValid;
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            if (validateForm()) {
                const response = await putMethod(
                    `${Apiurl.transfer}/${selected}`,
                    formValue
                );
                getTransferList();
                setFormValue({
                    from: 0,
                    to: 0,
                    currency_id: 0,
                    amount: "",
                    date: "",
                    note: "",
                });
                setErrors({});
                setModal(!modal);
            }
        } catch (err) {
        }
    };

    const setEditModal = (id) => {
        setModal(true);
        const selected = transferList.find((item) => item.id === id);
        setSelected(selected.id);

        setFormValue({
            from: selected.from,
            to: selected.to,
            currency_id: selected.currency_id,
            amount: atob(selected.amount),
            date: selected.date,
            note: selected.note,
            payment_type: "cash",
        });
    };

    const handleDelete = async (id) => {
        try {
            const confirmation = await showConfirmationDialog(
                "Are you sure you want to delete ?",
                "",
                "warning"
            );
            if (confirmation) {
                await deleteMethod(`${Apiurl.transfer}/${id}`)
                    .then((res) => {
                        getTransferList();
                    })
                    .catch((error) => {

                    });
            }
        } catch (error) {
        }
    };

    const UserTransferExcel = async () => {
        try {
            const response = await getMethod(`${Apiurl.user_transferList_excel}?search=${searchComp}`)
            const path = (Apiurl.routePath.replace('/api/v1', '') + response.data.file_name)
            generateExcel(path, "file.xlsx")
        } catch (error) {
            console.log('error: ', error)
        }
    }

    const generateExcel = async (path, name) => {
        window.open(path)
    }

    useEffect(() => {
        delayedSearch();
    }, [currentPage, searchQuery, limit, selectCurrency]);

    const handleSearch = (search) => {
        setSearchComp(search);
        if (inputTimeout) clearTimeout(inputTimeout);
        setInputTimeout(
            setTimeout(() => {
                setSearchQuery(search);
                setCurrentPage(1);
            }, 500)
        );
    };

    useEffect(() => {
        getUsers();
        getCurrencies()
    }, []);

    return (
        <div>
            <Navbar />
            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)} className="d-block">
                    <div className="container-fluid">
                        <div className="block-header">
                            <div className="row clearfix">
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <h1 style={{ width: "270px" }}>Update User Transfer</h1>
                                </div>
                            </div>
                        </div>
                        <div className="row clearfix">
                            <div className="col-sm-12">
                                <div className="">
                                    <div className="body">
                                        <form onSubmit={handleEditSubmit}>
                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    From
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <select
                                                    name="from"
                                                    value={formValue.from}
                                                    onChange={handleChange}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="">Select Sender...</option>
                                                    {users.map((user, i) => (
                                                        <option key={i} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.from && (
                                                <p className="useredit_err">{errors.from}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    To
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <select
                                                    name="to"
                                                    value={formValue.to}
                                                    onChange={handleChange}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="">Select receiver...</option>
                                                    {users.map((user, i) => (
                                                        <option key={i} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.to && (
                                                <p className="useredit_err">{errors.to}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Currency Type
                                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                                </label>
                                                <select
                                                    name="currency_id"
                                                    value={formValue.currency_id}
                                                    onChange={handleChange}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                >
                                                    <option value="">Select Currency Type...</option>
                                                    {currencies.map((currency, i) => (
                                                        <option key={i} value={currency.id}>
                                                            {currency.name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {errors.currency_id && (
                                                <p className="useredit_err">{errors.currency_id}</p>
                                            )}

                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Amount
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    name="amount"
                                                    value={formValue.amount}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    onChange={handleChange}
                                                    style={{ marginLeft: "8px" }}
                                                    placeholder="Enter Amount"
                                                />
                                            </div>
                                            {errors.amount && (
                                                <p className="useredit_err">{errors.amount}</p>
                                            )}
                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Date
                                                    <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="date"
                                                    name="date"
                                                    value={formValue.date}
                                                    onChange={handleChange}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                    placeholder="Enter Date"
                                                />
                                            </div>
                                            {errors.date && (
                                                <p className="useredit_err">{errors.date}</p>
                                            )}
                                            <div className="form-group row justify-content-between align-items-center">
                                                <label
                                                    className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 col-form-label"
                                                    style={{ whiteSpace: 'nowrap' }}
                                                >
                                                    Note
                                                    {/* <span
                                                        className="text-danger"
                                                        style={{ margin: "4px" }}
                                                    >
                                                        *
                                                    </span> */}
                                                </label>
                                                <input
                                                    type="text"
                                                    value={formValue.note}
                                                    name="note"
                                                    onChange={handleChange}
                                                    className="form-control input-height col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                                                    style={{ marginLeft: "8px" }}
                                                    placeholder="Enter Note"
                                                />
                                            </div>
                                            {errors.note && (
                                                <p className="useredit_err">{errors.note}</p>
                                            )}

                                            <div className="form-group d-flex justify-content-center">
                                                <div className="col-12 text-center mt-2">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success btn_bg_color"
                                                        style={{ marginRight: "30px" }}
                                                    >
                                                        Submit
                                                    </button>
                                                    <div
                                                        className="btn btn-secondary"
                                                        style={{ backgroundColor: "grey" }}
                                                        onClick={() => {
                                                            setModal(false);
                                                            setErrors({});
                                                        }}
                                                    >
                                                        Cancel
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalHeader>
            </Modal>

            <div id="main-content">
                <div className="container-fluid">
                    <div className="block-header">
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <h1 className="">Transfer List</h1>
                            </div>
                            {(isAdmin || userPermission?.create) && (
                                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                                    <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                                        <div className="mb-3 mb-xl-0 mr-5">
                                            <button className="btn btn-dark" onClick={UserTransferExcel} disabled={transferList.length === 0}>Export To Excel<i className="fa fa-download ml-2 text-success" aria-hidden="true"></i></button>
                                        </div>
                                        <div className="mb-3 mb-xl-0">
                                            <Link to="/transfer">
                                                <span className="btn btn-dark">Add Transfer</span>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row clearfix">
                        <div className="col-sm-12">

                            <div className="card">
                                <div className="body d-flex align-items-center flex-sm-nowrap flex-wrap" style={{ gap: "10px" }}>
                                    <select onChange={(e) => { setSelectCurrency(e.target.value) }} value={selectCurrency} className="border py-2 px-3 rounded bg-transparent">
                                        <option selected value={""}>Select Currency</option>
                                        {currencies.map((item) => (
                                            <option key={item.id} value={item.name}>{item.name}</option>
                                        ))}
                                    </select>

                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            onChange={(e) => {
                                                handleSearch(e.target.value);
                                            }}
                                            value={searchComp}
                                            className="form-control"
                                            placeholder="Search here..."
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text" id="search-mail">
                                                <i className="fa fa-search text-info"></i>
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="card">
                                <div className="body">
                                    <label style={{ fontWeight: 500 }}>Filter By Date : </label>
                                    <form>
                                        <div className="input-group flex-sm-nowrap flex-wrap align-items-center" style={{ gap: "10px" }}>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">From:</p>
                                                <input
                                                    type="date"
                                                    name="start_date"
                                                    style={{ margin: "0px 4px 0px 5px", height: "40px" }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.start_date}
                                                    max={dateRange?.end_date || defaultDate}
                                                    className="form-control"
                                                    placeholder="Start Date..."
                                                />
                                            </div>
                                            <div className="d-flex align-items-center w-100">
                                                <p className="m-0">To:</p>
                                                <input
                                                    type="date"
                                                    name="end_date"
                                                    style={{ marginLeft: '5px', height: "40px" }}
                                                    onChange={(e) => {
                                                        handleFilterDate(e);
                                                    }}
                                                    value={dateRange.end_date}
                                                    min={dateRange?.start_date}
                                                    max={defaultDate}
                                                    className="form-control"
                                                    placeholder="End Date..."
                                                />
                                            </div>
                                            <div className="ml-2" style={{ cursor: 'pointer' }} data-toggle="tooltip" data-placement="bottom" title="Search">
                                                <span className="input-group-text" id="search-mail" onClick={handleFilteredResult} >
                                                    <i className="fa fa-search edit-button text-info"></i>
                                                </span>
                                            </div>
                                        </div>
                                        {dateError && <p style={{ color: "red", fontSize: "13px", marginLeft: "5px", marginBottom: "0px" }}>{dateError}</p>}
                                    </form>
                                </div>
                            </div>

                            {isLoading ? (
                                <span className="loader"></span>
                            ) : (
                                <>
                                    <div className="table-responsive">
                                        <table className="table table-hover table-custom spacing5">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No</th>
                                                    <th>Date</th>
                                                    <th>From User</th>
                                                    <th>To User</th>
                                                    <th>Amount</th>
                                                    <th>Currency Type</th>
                                                    <th>Transfer By</th>
                                                    <th>Note</th>
                                                    {(isAdmin ||
                                                        userPermission?.edit ||
                                                        userPermission?.delete) && <th>Action</th>}
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {transferList.length > 0 ? (
                                                    transferList.map((list, i) => (
                                                        <React.Fragment key={i}>
                                                            <tr>
                                                                <td>#{(currentPage - 1) * limit + 1 + i}</td>
                                                                <td>{list.date}</td>
                                                                <td>{list.from_users.name}</td>
                                                                <td>{list.to_users.name}</td>
                                                                <td>{atob(list.amount)}</td>
                                                                <td>{list.currency.name}</td>
                                                                <td>{list?.transaction_by_users?.name || "-"}</td>
                                                                <td>{list.note}</td>

                                                                <td>
                                                                    {(isAdmin || userPermission?.edit) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Edit"
                                                                            onClick={() => setEditModal(list.id)}
                                                                        >
                                                                            <i className="fa fa-pencil text-success edit-button"></i>
                                                                        </button>
                                                                    )}
                                                                    {(isAdmin || userPermission?.delete) && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-default btn-sm"
                                                                            title="Delete"
                                                                            onClick={() => handleDelete(list.id)}
                                                                        >
                                                                            <i className="fa fa-trash-o text-danger delete-button"></i>
                                                                        </button>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    ))) : (
                                                    <tr>
                                                        <td style={{ borderTop: "none", textAlign: "center" }} colSpan={9}>No records found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {transferList.length > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={handlePageChange}
                                            limit={limit}
                                            onLimitChange={handleItemsPerPageChange}
                                        />
                                    }
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserTransferList;
