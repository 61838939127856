import React, { useEffect, useState } from "react";
import {
  getMethod,
  putMethod,
  deleteMethod,
} from "../../../services/base_service";
import { Apiurl } from "../../../services/apiRoute";
import { Modal, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
import debounce from "lodash/debounce";
import Pagination from "../../../services/Pagination";
import "../../../css/sidebar.css";
import {
  showConfirmationDialog,
} from "../../../services/alert_service";

const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [modal, setModal] = useState(false);
  const [role, setRole] = useState("");
  const [selected, setSelected] = useState(0);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState(50);

  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const rolePermissions = permissions.find(
    (item) => item.table_name === "roles"
  );
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  const getRoles = async () => {
    setIsLoading(true);
    await getMethod(Apiurl.role, {
      page: currentPage,
      pageSize: 5,
      search: searchQuery,
    })
      .then((res) => {
        setRoles(res.data.data);
        setTotalPages(res.data.total_page);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleDelete = async (id) => {
    try {
      const confirmation = await showConfirmationDialog(
        "Are you sure you want to delete ?",
        "",
        "warning"
      );
      if (confirmation) {
        await deleteMethod(`${Apiurl.role}/?id=${id}`)
          .then((res) => {
            getRoles();
          })
          .catch((error) => {
          });
      }
    } catch (error) {
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const params = {
      role: role,
    };
    if (role === "") {
      setErrors({ role: "Role Cannot be empty" });
    }
    if (role) {
      const response = await putMethod(
        `${Apiurl.role}/?id=${selected}`,
        params
      );
      if (response.status === 200) {
        setModal(!modal);
        getRoles();
        setRole("");
        setErrors({ ...errors, role: "" });
      }
    }
  };
  const setEditModal = (id) => {
    setModal(true);
    const selected = roles.find((item) => item.id === id);
    setSelected(selected.id);
    setRole(selected.role);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleItemsPerPageChange = (event) => {
    setLimit(Number(event.target.value));
  };

  const delayedSearch = debounce(() => {
    getRoles();
  }, 300);

  useEffect(() => {
    delayedSearch();
  }, [currentPage, searchQuery, limit]);

  return (
    <div>
      <Navbar />
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>
          <div className="container-fluid">
            <div className="block-header">
              <div className="row clearfix">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h1 style={{ width: "270px" }}>Update Roles</h1>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="col-sm-12">
                <div className="">
                  <div className="body">
                    <form onSubmit={handleEditSubmit}>
                      <div className="form-group row">
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ marginRight: "50px" }}
                        >
                          Role.
                        </label>
                        <div className="col-md-6 col-sm-10">
                          <input
                            type="text"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="form-control"
                            style={{ width: "300px" }}
                            placeholder="Enter Role."
                          />
                        </div>
                      </div>
                      {errors && <p className="roleedir_err">{errors.role}</p>}
                      <div
                        className="form-group row"
                        style={{ marginTop: "35px" }}
                      >
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-md-6 col-sm-10">
                          <button
                            type="submit"
                            className="btn btn-success btn_bg_color"
                            style={{ marginRight: "30px" }}
                          >
                            Submit
                          </button>
                          <div
                            className="btn btn-secondary"
                            style={{ backgroundColor: "grey" }}
                            onClick={() => {
                              setModal(false);
                              setErrors({});
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
      </Modal>

      <div id="main-content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Roles</h1>
              </div>
              {(isAdmin || rolePermissions?.create) && (
                <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                  <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                    <div className="mb-3 mb-xl-0">
                      <Link to="/addroles">
                        <span className="btn btn-dark">Add New</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-lg-8 col-md-12 col-sm-12 text-lg-right">
                <div className="d-flex align-items-center justify-content-lg-end mt-4 mt-lg-0 flex-wrap vivify pullUp delay-550">
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0 hidden-xs">
                    <p className="text-muted mb-1">Permanent</p>
                    <h5 className="mb-0">214</h5>
                  </div>
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0">
                    <p className="text-muted mb-1">Contract</p>
                    <h5 className="mb-0">206</h5>
                  </div>
                  <div className="border-right pr-4 mr-4 mb-2 mb-xl-0">
                    <p className="text-muted mb-1">Total</p>
                    <h5 className="mb-0">420</h5>
                  </div>
                  <div className="mb-3 mb-xl-0">
                    <Link to="/addroles">
                      <span className="btn btn-dark">Add New</span>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              <div className="card">
                <div className="body">

                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="search"
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search here..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text" id="search-mail">
                        <i className="fa fa-search text-info"></i>
                      </span>
                    </div>
                  </div>

                </div>
              </div>
              {isLoading ? (
                <span className="loader"></span>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Name</th>

                          {(isAdmin ||
                            rolePermissions?.edit ||
                            rolePermissions?.delete) && <th>Action</th>}
                        </tr>
                      </thead>

                      <tbody>
                        {roles.length > 0 ? (
                          roles.map((list, i) => (
                            <React.Fragment key={i}>
                              <tr>
                                <td>#{i + 1}</td>
                                <td>{list.role}</td>
                                <td>
                                  {(isAdmin || rolePermissions?.edit) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Edit"
                                      onClick={() => setEditModal(list.id)}
                                    >
                                      <i className="fa fa-pencil text-success edit-button"></i>
                                    </button>
                                  )}
                                  {(isAdmin || rolePermissions?.delete) && (
                                    <button
                                      type="button"
                                      className="btn btn-default btn-sm"
                                      title="Delete"
                                      onClick={() => handleDelete(list.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger delete-button"></i>
                                    </button>
                                  )}
                                </td>
                              </tr>
                            </React.Fragment>
                          ))) : (
                          <tr>
                            <td style={{ borderTop: "none", textAlign: "center" }} colSpan={3}>No records found</td>
                          </tr>
                        )}
                      </tbody>

                    </table>
                  </div>
                  {roles.length > 0 &&
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                      limit={limit}
                      onLimitChange={handleItemsPerPageChange}
                    />
                  }
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roles;
